<template>
  <div>
    <h2 v-html="$t('search_processing_form_templates_title')"></h2>
    <!-- Tiarart -->
    <v-select
      id="search_processing_form_templates_animal_types"
      v-model="searchCardStore.Dairy_animalTypeId"
      :label="$t('search_processing_form_templates_animal_types')"
      :items="animalTypesIncVirtual"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!-- Verwertung -->
    <v-select
      id="search_processing_form_templates_processing"
      v-model="searchCardStore.Dairy_dairyProcessing"
      :label="$t('search_processing_form_templates_processing')"
      :items="dairyProcessings"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!-- Periodizität -->
    <v-select
      id="search_processing_form_templates_period_id"
      v-model="searchCardStore.Dairy_formPeriodId"
      :label="$t('search_processing_form_templates_period_id')"
      :items="formPeriods"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!-- Produkt mit Zulagen -->
    <v-select
      id="search_processing_form_templates_has_subsidy"
      v-model="searchCardStore.Dairy_hasSubsidyProduct"
      :label="$t('search_processing_form_templates_has_subsidy')"
      :placeholder="$t('search_processing_form_templates_all')"
      :items="search_processing_form_templates_subsidy"
      item-title="text"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSearchProcessingFormTemplateStore } from '@/store/SearchProcessingFormTemplateStore'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useDairyProcessingsStore } from '@/store/enums/DairyProcessingsStore'
import { useFormPeriodsStore } from '@/store/enums/FormPeriodsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProcessingFormTemplates',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      search_processing_form_templates_subsidy: [
        { id: 0, text: this.$t('search_processing_form_templates_no') },
        { id: 1, text: this.$t('search_processing_form_templates_yes') }
      ]
    }
  },
  computed: {
    animalTypesIncVirtual() {
      return useAnimalTypesStore().AnimalTypesIncVirtual
    },
    dairyProcessings() {
      return useDairyProcessingsStore().dairyProcessings
    },
    formPeriods() {
      return useFormPeriodsStore().formPeriods
    }
  },

  beforeMount() {
    useAnimalTypesStore().fetchAnimalTypesIncVirtual()
    useFormPeriodsStore().fetchFormPeriods()
    useDairyProcessingsStore().fetchDairyProcessings()
    this.setSearchCardStore(useSearchProcessingFormTemplateStore(), true)
  }
})
</script>
