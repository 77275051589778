import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchProcessingFormTemplateStore = defineStore('searchProcessingFormTemplateStore', {
  state: () => ({
    Dairy_animalTypeId: null as number | null,
    Dairy_dairyProcessing: null as number | null,
    Dairy_formPeriodId: null as number | null,
    Dairy_hasSubsidyProduct: null as number | null,
    items: [
      { key: 'Dairy_animalTypeId', operation: ':' },
      { key: 'Dairy_dairyProcessing', operation: ':' },
      { key: 'Dairy_formPeriodId', operation: ':' },
      { key: 'Dairy_hasSubsidyProduct', operation: ':' }
    ] as TermItemDefinition[]
  })
})
